import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const DownloadChangeButton = styled(IconButton)`
  padding: 6px 22px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  font-size: small;
`;

export const UploadButton = styled(IconButton)`
  border-radius: 0.5em;
  cursor: pointer;
`;
