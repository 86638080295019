import { getTemplate } from "../components/Parts_Form/Form/Form-facade-service";
import { FormField, FormSelectedValue, TemplateType } from "../models/Form";

export const formStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "2px solid #000",
  boxshadow: 24,
  p: 4,
};

export const basicTypes = ["int", "text", "date", "timestamp", "password"];
export const NoneTypedObject = ["notes"];
export const returnNumberInSelect = ["User_meta_keys"];

export let formSelectedValues: FormSelectedValue[] = [
  { fieldName: "emails", type: "" },
  { fieldName: "websites", type: "" },
  { fieldName: "addresses", type: "", open: false },
  { fieldName: "phone_numbers", type: "" },
  { fieldName: "social_profiles", type: "" },
];

export const deleteDialog = {
  title: "Confirm delete",
  description: "Are you sure you want to delete",
};

export const templateMapping: Record<
  TemplateType,
  () => Promise<{ fields: FormField[] }>
> = {
  addresses: () =>
    import("../templates/contact/Addresses_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  social_profiles: () =>
    import("../templates/contact/SocialProfiles_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  emails: () =>
    import("../templates/contact/Emails_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  phone_numbers: () =>
    import("../templates/contact/Phone_temnplate_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  websites: () =>
    import("../templates/contact/Websites_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  contacts: () =>
    import("../templates/contact/Contacts_template_v3.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  notes: () =>
    import("../templates/contact/Notes_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  export: () =>
    import("../templates/Export_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  user_meta_keys: () =>
    import("../templates/user/User_meta_keys_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  user: () =>
    import("../templates/user/User_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  magicManipulation: () =>
    import("../templates/MagicManipulation_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  tasks: () =>
    import("../templates/tasks/TaskManagment_template.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
};

export const getChangeFormMethodParameter = async (
  rowState: any,
  formSelectedValue: FormSelectedValue
) => {
  return {
    formProps: {
      selectedRow: rowState || "",
      formTemplate: await getTemplate(formSelectedValue.fieldName),
      tableName: formSelectedValue.fieldName,
    },
    open: true,
    subForm: true,
  };
};

export const getFormProps = (
  isEvent: boolean,
  formEventData: any,
  subForm?: boolean
) => {
  return isEvent
    ? {
        row: formEventData.selectedRow,
        template: formEventData.formTemplate,
        entityName: formEventData.tableName,
        ...(subForm && { toggleAll: true }),
      }
    : {
        row: formEventData.row,
        template: formEventData.template,
        entityName: formEventData.entityName,
      };
};
