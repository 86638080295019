import axios from "axios";
import { ColumnName } from "../models/Table";
// export const BASE_URL = "http://localhost:3010/api/"; // dev
export const BASE_URL = "api/"; // prod

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-API-Secret": "dsjabjvgyt12354r62frvui34989g8v7qc3iu2igh349fgqb4",
  },
});

const updateData = async <T,>(endpoint: string, data: any) => {
  return await axiosInstance.put<T>(endpoint, data);
};

const createData = async <T,>(endpoint: string, data: any) => {
  return await axiosInstance.post<T>(endpoint, data);
};

const postData = async <T,>(endpoint: string, data: any) => {
  return await axiosInstance.post<T>(endpoint, data);
};

const getAllData = async <T,>(endpoint: string): Promise<T> => {
  return (await axiosInstance.get<T>(endpoint))?.data;
};

const deleteData = async (endpoint: string) => {
  return await axiosInstance.delete(endpoint);
};

const getColumnNames = async (endpoint: string, tableName: any) => {
  return await (
    await axiosInstance.post<ColumnName[]>(endpoint, {
      table_name: tableName,
    })
  ).data;
};

export {
  getAllData,
  createData,
  updateData,
  deleteData,
  getColumnNames,
  postData,
};
