import { IconButton, useTheme } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOffIcon from "@mui/icons-material/EditOff";

import NativeSelectComponent from "../../Select/NativeSelect-component";
import { useDirigentTableStore } from "../../../providers/Table-provider";
import React from "react";
import {
  ChangeFormValueCallback,
  ComplexState,
  FormComponentProps,
  FormSelectedValue,
} from "../../../models/Form";
import {
  changeStateBasicElementForm,
  changeFormState,
  changeSelectedValueState,
  checkConditionSetSelectedValueEvent,
  getSelectedElement,
  getTypedCSSDeleteButton,
  handleEdit,
  hasFields,
  initialEditState,
  isSelectedInputButton,
  isTypedSelect,
  isUnTypedSelect,
  showInputs,
  getFormattedDate,
} from "./Form-facade-service";
import "./Form.scss";

import {
  basicTypes,
  deleteDialog,
  formSelectedValues,
  getChangeFormMethodParameter,
  NoneTypedObject,
  returnNumberInSelect,
} from "../../../models_objects/Form-o";
import {
  FormLabelStyled,
  IconButtonStyled,
  IconButtonStyledNotes,
  RowContainer,
  StyledInput,
  Wrapper,
} from "./Form-styled";
import AlertDialogSlide from "../../Dialog/Alert-dialog-component";
import FormControlPhotoComponent from "../Form_control_photo/Form-control-photo-component";
import EmojiAreaComponent from "../EmojiTextArea/Emoji-TextArea-component";

const defaultState: ComplexState = {
  rowState: {},
  formEdit: {},
  isActive: {},
  selectedValue: formSelectedValues,
};

const FormComponent: React.FC<FormComponentProps> = React.memo(
  ({
    row,
    template,
    entityName,
    toggleAll,
    requestAction,
    Ids,
    isUpdate,
    changeForm,
    submitRequest,
    deleteRow,
  }) => {
    const [tableState, setState] = useState<ComplexState>(defaultState);
    const { setTableStore } = useDirigentTableStore();
    const theme = useTheme();

    useEffect(() => {
      setState((state) => ({
        ...state,
        rowState: row,
        formEdit: initialEditState(template),
      }));
    }, [row, template]);

    const deleteEvent = (
      fieldName: string,
      id: number,
      index?: number,
      contact_id?: number
    ): void => {
      if (index !== undefined) changeFormTemplate(fieldName, index);
      if (!!id) deleteRow({ id, fieldName, setTableStore, contact_id });
    };

    const changeFormValue = useCallback(
      (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        subItemName: string,
        entityRelationshipId?: number,
        imageUrl?: string,
        callback?: ChangeFormValueCallback
      ): void => {
        changeFormState(
          event,
          subItemName,
          setState,
          entityRelationshipId,
          imageUrl,
          callback
        );
      },
      []
    );

    const changeBasicSelectFormValue = (
      fieldName: string,
      selectValue: number | string
    ) => {
      changeStateBasicElementForm(fieldName, setState, selectValue);
    };

    const setSelectedValueEvent = async (
      formSelectedValue: FormSelectedValue
    ): Promise<void> => {
      if (checkConditionSetSelectedValueEvent(formSelectedValue)) {
        formSelectedValue.indexObject != null &&
          getChangeFormMethodParameter(
            tableState.rowState[formSelectedValue.fieldName][
              formSelectedValue.indexObject
            ],
            formSelectedValue
          ).then(({ formProps, open, subForm }) => {
            changeForm(formProps, open, subForm);
          });
      }
      changeSelectedValueState(setState, formSelectedValue);
    };

    const changeFormTemplate = (fieldName: string, index?: number): void => {
      setState((prevState) => {
        const newState = { ...prevState };
        const fieldArray = newState.rowState[fieldName] || [];
        newState.rowState[fieldName] =
          index !== undefined
            ? fieldArray.filter((_: any, i: number) => i !== index)
            : [...fieldArray, ""];
        return newState;
      });
    };
    return (
      <Wrapper key={entityName} $isLight={theme.palette.mode === "light"}>
        <form
          key={`${entityName}-`}
          onSubmit={(event) =>
            submitRequest({
              event,
              rowState: tableState.rowState,
              entityName: entityName || "",
              requestAction,
              Ids,
              isUpdate,
            })
          }
        >
          {hasFields(template) &&
            template?.fields
              .filter((o) => !o.non_visible)
              .map((field, indexTemplate) => (
                <RowContainer
                  key={field.name || indexTemplate}
                  $isLight={theme.palette.mode === "light"}
                  $isNotes={field.name === "notes"}
                  $isSelect={field.type === "select"}
                  $isPhoto={field.type === "text_url_photo"}
                  $space={field?.spaceBetween}
                >
                  {field.label && (
                    <FormLabelStyled $isLight={theme.palette.mode === "light"}>
                      {field.label}:
                    </FormLabelStyled>
                  )}

                  {field.type === "text_url_photo" && (
                    <FormControlPhotoComponent
                      id={tableState.rowState["id"]}
                      photoUrlIn={tableState.rowState[field.name] || ""}
                      savePhoto={(e, url) => {
                        changeFormValue(
                          e,
                          field.itemName || "",
                          tableState.rowState["id"],
                          url,
                          (updatedState) => {
                            submitRequest({
                              event: e,
                              rowState: updatedState.rowState,
                              entityName: entityName || "",
                            });
                          }
                        );
                      }}
                    />
                  )}

                  {basicTypes.includes(field.type) && (
                    <>
                      <StyledInput
                        id={field.name}
                        key={`${field.name}-${indexTemplate}`}
                        value={
                          field.type === "timestamp"
                            ? getFormattedDate(
                                tableState.rowState?.[field.name]
                              )
                            : tableState.rowState?.[field.name] ?? ""
                        }
                        type={field.type}
                        name={field.name}
                        onChange={(event) => changeFormValue(event, "")}
                        readOnly={field.type === "timestamp"}
                        $isLight={theme.palette.mode === "light"}
                      />
                    </>
                  )}

                  {field.type === "textarea" && (
                    <>
                      <EmojiAreaComponent
                        fieldValue={tableState.rowState?.[field.name] ?? ""}
                        setField={(e) => changeFormValue(e, "")}
                        name={field.name}
                      />
                    </>
                  )}

                  {/* {field.type === "date" && (
                    <>
                      <DatePickerComponent
                        value={tableState.rowState?.[field.name] ?? ""}
                        onDateChange={(event) => changeFormValue(event, "")}
                      />
                    </>
                  )} */}

                  {isTypedSelect(field) && (
                    <div className="sub-items-box" id={`${field.name}-box`}>
                      {tableState.rowState[field.name]?.map(
                        (subField: any, index: number) => (
                          <>
                            <div
                              key={`${field.name}-${subField.id || index}`}
                              className="item"
                            >
                              <AlertDialogSlide
                                title={deleteDialog.title}
                                description={deleteDialog.description.concat(
                                  " ",
                                  field.name?.replace(/s/gi, " ?") || "record"
                                )}
                                agreeAction={() =>
                                  deleteEvent(
                                    field.name,
                                    subField.id,
                                    index,
                                    tableState.rowState["id"]
                                  )
                                }
                              >
                                <IconButtonStyledNotes
                                  id={field.name}
                                  className={getTypedCSSDeleteButton(
                                    tableState,
                                    field.name,
                                    indexTemplate,
                                    index
                                  )}
                                  $isSelected={isSelectedInputButton(
                                    tableState.isActive[indexTemplate],
                                    index
                                  )}
                                >
                                  <CloseRoundedIcon />
                                </IconButtonStyledNotes>
                              </AlertDialogSlide>
                              <StyledInput
                                id={`${index}`}
                                value={`${subField.note || ""}`}
                                type={field.type}
                                name={field.name}
                                onChange={(e) =>
                                  changeFormValue(
                                    e,
                                    field.itemName || "",
                                    tableState.rowState["id"]
                                  )
                                }
                                readOnly={tableState.formEdit[field.name]}
                                $isLight={theme.palette.mode === "light"}
                                $isSelected={isSelectedInputButton(
                                  tableState.isActive[indexTemplate],
                                  index
                                )}
                                $fullWidth={true}
                              />
                            </div>
                          </>
                        )
                      )}
                      {NoneTypedObject.includes(field.name) && (
                        <IconButtonStyled
                          style={{
                            width: "fit-content",
                            margin: "8px auto",
                          }}
                          className="add-input-field"
                          onClick={() => changeFormTemplate(field.name)}
                        >
                          <AddRoundedIcon />
                        </IconButtonStyled>
                      )}
                    </div>
                  )}

                  {field.type === "basic_select" && (
                    <NativeSelectComponent
                      options={field.values || ["doesnt work..."]}
                      name={field.name}
                      value={tableState.rowState[field.name]}
                      changeSelectedValue={(selectType) =>
                        changeBasicSelectFormValue(field.name, selectType)
                      }
                      returnValue={
                        returnNumberInSelect.includes(entityName ?? "")
                          ? "number"
                          : "string"
                      }
                    ></NativeSelectComponent>
                  )}

                  {isUnTypedSelect(tableState, field) && (
                    <div className="sub-items-box" id={`${field.name}-box`}>
                      <NativeSelectComponent
                        options={tableState.rowState[field.name]}
                        name={field.name}
                        value=""
                        changeSelectedValue={(selectType) =>
                          setSelectedValueEvent({
                            fieldName: field.name,
                            type: selectType,
                            indexObject:
                              tableState.rowState[field.name]?.findIndex(
                                (o: any) =>
                                  o.type === selectType ||
                                  o.platform === selectType
                              ) || 0,
                            multi: !!field.multi,
                          })
                        }
                      ></NativeSelectComponent>
                      {!field.multi &&
                        Array.isArray(tableState.rowState[field.name]) &&
                        tableState.rowState[field.name]
                          ?.map((item: any) =>
                            getSelectedElement(
                              item,
                              field,
                              tableState.selectedValue
                            )
                          )
                          ?.map(
                            (customField: any, index: number) =>
                              (!customField || customField.index === index) && (
                                <div
                                  key={`${field.name}-${
                                    customField?.id || index
                                  }`}
                                  className="item"
                                >
                                  <AlertDialogSlide
                                    title={deleteDialog.title}
                                    description={deleteDialog.description.concat(
                                      " ",
                                      field.name?.replace(/s/gi, " ?") ||
                                        "record"
                                    )}
                                    agreeAction={() =>
                                      deleteEvent(
                                        field.name,
                                        customField.id,
                                        index,
                                        tableState.rowState["id"]
                                      )
                                    }
                                  >
                                    <IconButton id={field.name}>
                                      <CloseRoundedIcon />
                                    </IconButton>
                                  </AlertDialogSlide>
                                  <StyledInput
                                    id={`${customField?.index || 0}`}
                                    value={`${customField?.dynamicAttribute}`}
                                    type={field.type}
                                    name={field.name}
                                    onChange={(e) =>
                                      changeFormValue(
                                        e,
                                        field.itemName || "",
                                        tableState.rowState["id"]
                                      )
                                    }
                                    readOnly={tableState.formEdit[field.name]}
                                    $isLight={theme.palette.mode === "light"}
                                    $isSelected={isSelectedInputButton(
                                      tableState.isActive[indexTemplate],
                                      index
                                    )}
                                    $fullWidth={true}
                                  />
                                </div>
                              )
                          )}
                    </div>
                  )}

                  {field.editable && !toggleAll && (
                    <>
                      {!field.multi && !basicTypes.includes(field.type) && (
                        <IconButtonStyled
                          id={field.name}
                          onClick={() =>
                            handleEdit(setState, field.name, false)
                          }
                        >
                          {tableState.formEdit[field.name] ? (
                            <EditOffIcon />
                          ) : (
                            <ModeEditOutlineIcon />
                          )}
                        </IconButtonStyled>
                      )}
                      {field.type === "select" &&
                        !NoneTypedObject.includes(field.name) && (
                          <IconButtonStyled
                            style={{
                              marginRight: !!field.multi ? "24px" : "",
                            }}
                            onClick={() => changeFormTemplate(field.name)}
                          >
                            <AddRoundedIcon />
                          </IconButtonStyled>
                        )}
                    </>
                  )}

                  {field.type === "select" &&
                    NoneTypedObject.includes(field.name) && (
                      <IconButtonStyled
                        id={field.name}
                        onClick={() => showInputs(setState, indexTemplate)}
                      >
                        <MoreHorizIcon />
                      </IconButtonStyled>
                    )}
                </RowContainer>
              ))}

          <div className="action-container">
            <AlertDialogSlide
              title={deleteDialog.title}
              description={deleteDialog.description.concat(
                " ",
                entityName?.replace(/s(?=[^s]*$)/i, " ?") || "record"
              )}
              agreeAction={() =>
                deleteEvent(
                  entityName || "",
                  tableState.rowState.id ?? tableState.rowState,
                  undefined,
                  tableState?.rowState?.contact_id
                )
              }
            >
              <IconButtonStyled className="delete-event">
                <DeleteIcon style={{ cursor: "pointer" }} />
                Delete
              </IconButtonStyled>
            </AlertDialogSlide>

            {!!toggleAll && (
              <IconButtonStyled
                onClick={() => handleEdit(setState, "", toggleAll)}
              >
                {!tableState.formEdit.id ? (
                  <EditOffIcon />
                ) : (
                  <ModeEditOutlineIcon />
                )}
              </IconButtonStyled>
            )}

            <IconButtonStyled className="submit-event" type="submit">
              <SendIcon style={{ cursor: "pointer" }} />
            </IconButtonStyled>
          </div>
        </form>
      </Wrapper>
    );
  }
);

export default FormComponent;
