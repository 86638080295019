import { enqueueSnackbar } from "notistack";
import { UploadPhotoEvent } from "../../../models/Form";
import { BASE_URL } from "../../../hooks/dataServiceHook";

const fetchBlob = async (url: string) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "X-API-Secret": "dsjabjvgyt12354r62frvui34989g8v7qc3iu2igh349fgqb4",
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.blob();
};

const createDownloadLink = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const uploadPhoto = async ({
  formData,
  photoUrl,
  setPhotoUrl,
  savePhoto,
}: UploadPhotoEvent) => {
  await fetch(`${BASE_URL}contact/upload/` + photoUrl, {
    method: "POST",
    body: formData,
    headers: {
      "X-API-Secret": "dsjabjvgyt12354r62frvui34989g8v7qc3iu2igh349fgqb4",
    },
  })
    .then(async (res) => {
      enqueueSnackbar("New photo was uploaded", {
        variant: "success",
      });
      const { imageUrl } = await res.json();
      console.log(imageUrl);
      setPhotoUrl();
      savePhoto(imageUrl);
    })
    .catch((err) => {
      enqueueSnackbar("Something wrong", {
        variant: "error",
      });
    });
};

const getFileName = (url: string): string => {
  return url.split("/").pop() || "download_fileName";
};

export { fetchBlob, createDownloadLink, uploadPhoto, getFileName };
